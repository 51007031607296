import { Injectable } from '@angular/core';
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import { Router } from '@angular/router';
import { AddmoduleComponent } from './addmodule/addmodule.component';
import { SubAdminPasswordComponent } from './sub-admin-password/sub-admin-password.component';
import { SubmoduleComponent } from './submodule/submodule.component';

@Injectable({
  providedIn: 'root',
})
export class DialogService {
  constructor(public dialog: MatDialog,private router: Router) {}

  addModule(type: any, data: Array<any>): any {
    const dialogRef = this.dialog.open(AddmoduleComponent);
    dialogRef.componentInstance.dialogType = type;
    dialogRef.componentInstance.parentData = data;

    return dialogRef.afterClosed();
  }

  importModuleSheet(id: string): any {
    const dialogRef = this.dialog.open(SubmoduleComponent);
    dialogRef.componentInstance.moduleId = id;

    return dialogRef.afterClosed();
  }


  subAdminPassword(data: any): any {
    const dialogRef = this.dialog.open(SubAdminPasswordComponent);
    dialogRef.componentInstance.parentData = data;

    return dialogRef.afterClosed().subscribe((res:any)=>{
      if(res){
        let url: string = '';
        url = this.router.url;
        this.router
          .navigateByUrl('/refresh', { skipLocationChange: true })
          .then(() => {
            this.router.navigateByUrl(url);
          });
      }
    })
  }
}
